/**
 * sortLinksAtoZ
 * @param {array} links - list of links data
 * @returns {array} sorted list of links data based on names
 */
const sortLinksAtoZ = (links) =>
  [...links]?.sort((a, b) =>
    a.entityContent.name.localeCompare(b.entityContent.name)
  );

export default sortLinksAtoZ;
