import * as Sentry from '@sentry/react';
import SplunkOtelWeb from '@splunk/otel-web';
import SplunkSessionRecorder from '@splunk/otel-web-session-recorder';

const initMonitoring = (env) => {
  // SENTRY
  Sentry.init({
    dsn: 'https://1dc122537de8630d04b83b7bd3e5a517@o4506034322079744.ingest.sentry.io/4506058219651072',
    environment: env,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: env === 'prod' ? 0.1 : 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  // SPLUNK
  if (env !== 'prod') {
    SplunkOtelWeb.init({
      realm: 'au0',
      rumAccessToken: '6eM1WLvdaCHtlkyqk5pqrQ',
      applicationName: 'links',
      deploymentEnvironment: env,
    });

    SplunkSessionRecorder.init({
      realm: 'au0',
      rumAccessToken: '6eM1WLvdaCHtlkyqk5pqrQ',
    });
  }
};

export default initMonitoring;
