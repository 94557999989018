import React from 'react';
import Category from './category/Category';
import c from './categories.module.scss';
import { useMedia } from '@monash/portal-react';

const Categories = ({ list }) => {
  const size = useMedia();

  return (
    <section
      aria-label="Categories"
      className={size === 'S' ? [c.categories, c.mini].join(' ') : c.categories}
    >
      <ul>
        {list.map((item, i) => (
          <li key={item.name + i}>
            <Category name={item.name} icon={item.icon} to={item.path} />
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Categories;
