import React from 'react';
import c from './page-container.module.scss';

const PageContainer = ({ children, type = 'default' }) => (
  <main className={[c.pageContainer, c[type]].join(' ')}>
    <div className={c.contentContainer} id="contentContainer">
      {children}
    </div>
  </main>
);

export default PageContainer;
