import React from 'react';

export const ErrorIllustration = () => (
  <svg
    width="320"
    height="337"
    viewBox="0 0 320 337"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M166.393 336.828H74.8456C74.2597 336.828 73.7033 336.571 73.3233 336.125L0.492173 250.65C-0.907428 249.007 0.92739 246.619 2.87529 247.547L74.0607 281.49C75.5692 282.209 77.2423 280.871 76.8716 279.241L51.2866 166.764C50.7825 164.547 53.778 163.341 54.9503 165.288L113.236 262.11C114.093 263.533 116.21 263.353 116.813 261.805L156.692 159.465C157.516 157.351 160.666 158.024 160.553 160.29L155.288 266.078C155.206 267.713 157.017 268.748 158.385 267.848L211.245 233.06C213.075 231.855 215.231 234.047 213.997 235.857L181.925 282.896C180.817 284.52 182.473 286.601 184.305 285.885L227.707 268.938C229.709 268.156 231.366 270.65 229.871 272.192L167.829 336.22C167.452 336.609 166.934 336.828 166.393 336.828Z"
      fill="#E8EEF4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M131.454 244.965L97.984 292.78L125.013 336.828H116.801L90.817 294.485L89.6162 292.528L90.9328 290.647L125.72 240.951L131.454 244.965Z"
      fill="#EA580C"
    />
    <path
      d="M119.85 229.961C120.797 228.601 122.666 228.265 124.026 229.212L142.67 242.185C144.03 243.131 144.365 245.001 143.419 246.361L141.169 249.594C140.222 250.954 138.353 251.29 136.993 250.343L118.349 237.37C116.989 236.423 116.654 234.554 117.6 233.194L119.85 229.961Z"
      fill="#EA580C"
    />
    <rect
      x="105.351"
      y="233.02"
      width="32.1518"
      height="39.2967"
      rx="3"
      transform="rotate(-104.202 105.351 233.02)"
      fill="#A1B6CA"
    />
    <path
      d="M67.9553 72.5114C64.8473 83.8924 64.123 95.5915 67.081 107.28L84.0001 103.5C82.8181 98.8298 80.4155 88.7045 81.7253 77.7785C82.4163 72.0138 82.9165 65.4947 86.7383 54.3347C87.2224 52.9209 86.6271 51.3538 85.3086 50.6506L83.5747 49.7259C82.476 49.1399 81.1227 49.2839 80.2298 50.1519C75.9997 54.2637 71.115 60.9409 67.9553 72.5114Z"
      fill="#A1B6CA"
    />
    <path
      d="M89.0521 181.869C87.4459 182.276 85.8143 181.303 85.4078 179.697L67.081 107.28L114.39 95.3074C115.996 94.9009 117.628 95.8735 118.034 97.4797L135.625 166.989C136.031 168.595 135.059 170.226 133.453 170.633L89.0521 181.869Z"
      fill="#A1B6CA"
    />
    <path
      d="M102.626 189.489C103.853 194.338 102.981 199.994 100.061 201.194L133.394 192.758C130.578 192.549 128.178 186.708 127.302 183.245C126.425 179.781 129.763 172.949 133.763 170.554L88.7413 181.948C93.2895 181.718 101.399 184.641 102.626 189.489Z"
      fill="#718199"
    />
    <path
      d="M131.869 152.147L122.118 113.619C122.118 113.619 123.384 118.619 133.774 115.99C202.797 97.14 263.456 79.7838 284.549 73.7141C288.898 72.4625 293.39 75.0676 294.5 79.455L299.244 98.199C300.339 102.524 297.713 106.902 293.374 107.94C272.18 113.013 210.725 127.761 141.073 144.834C130.683 147.463 131.869 152.147 131.869 152.147Z"
      fill="#204F88"
    />
    <path
      d="M126.994 132.883L122.118 113.619C122.118 113.619 123.384 118.619 133.774 115.99C202.797 97.1398 263.456 79.7835 284.549 73.7138C288.898 72.4623 293.39 75.0673 294.5 79.4547L297.169 89.9997C297.169 89.9997 213.378 111.709 126.994 132.883Z"
      fill="#4585D3"
    />
  </svg>
);
