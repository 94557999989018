import React, { useState } from 'react';
import { VirtuallyHidden } from '@monash/portal-react';

// create the context
export const RefContext = React.createContext();

const AccessibilityProvider = ({ children }) => {
  const [refTable, setRefTable] = useState({});
  const [appLiveAssertiveMsg, setAppLiveAssertiveMsg] = useState('');
  const [appLivePoliteMsg, setAppLivePoliteMsg] = useState('');
  return (
    <RefContext.Provider
      value={{
        refTable,
        setRefTable,
        // live messages
        setAppLiveAssertiveMsg,
        setAppLivePoliteMsg,
      }}
    >
      <>
        <VirtuallyHidden id="app-live-regions">
          <div aria-live="assertive" aria-atomic="true">
            {appLiveAssertiveMsg}
          </div>
          <div aria-live="polite" aria-atomic="true">
            {appLivePoliteMsg}
          </div>
        </VirtuallyHidden>
        {children}
      </>
    </RefContext.Provider>
  );
};

export default AccessibilityProvider;
