import React from 'react';
import Banner from './banner/Banner';
import Categories from './categories/Categories';
import PageContainer from '../../layouts/page-container/PageContainer';
import { categoryData } from '../category/data';

const Home = () => {
  return (
    <PageContainer type="center">
      <Banner />
      <Categories list={categoryData} />
    </PageContainer>
  );
};

export default Home;
