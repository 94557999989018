/**
 * filterLinksByCategory
 * @param {array} categories - list of selected categories to be matched
 * @param {array} links - list of links data
 * @returns {array} filtered list of links data based on categories
 */
const filterLinksByCategory = (categories, links) =>
  links?.filter((link) =>
    categories.some((c) => link.entityContent.categories.includes(c))
  );

export default filterLinksByCategory;
