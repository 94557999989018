import React from 'react';
import LocalError from '../../ui/local-error/LocalError';

const SearchError = () => (
  <LocalError
    title="Whoops, something went wrong ..."
    message="It looks like we're having trouble retrieving links right now. We'll have them back up as soon as we can."
  />
);

export default SearchError;
