import { PageNotFound } from '@monash/portal-frontend-common';
import React, { useEffect } from 'react';

const PageNotFoundWrapper = ({ setShowSidebar, ...props }) => {
  useEffect(() => {
    setShowSidebar(false);

    return () => {
      setShowSidebar(true);
    };
  }, []);
  return <PageNotFound {...props} />;
};

export default PageNotFoundWrapper;
