import React, { useContext, useState, useEffect } from 'react';
import { SearchBox } from '@monash/portal-react';
import { SearchContext } from '../../providers/search-provider/SearchProvider';
import { getIcon } from '@monash/portal-frontend-common';

const SearchBar = () => {
  const {
    searchTarget,
    runSearch,
    runSearchSuggestions,
    searchSuggestionsResult,
  } = useContext(SearchContext);

  const [searchString, setSearchString] = useState(searchTarget);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search).get('query');
    if (query) {
      runSearch(query, false);
    }
  }, []);

  useEffect(() => {
    runSearchSuggestions(searchString.trim());
  }, [runSearchSuggestions, searchString]);

  useEffect(() => {
    setSearchString(searchTarget);
  }, [searchTarget]);

  useEffect(() => {
    searchSuggestionsResult?.quickLinks &&
      searchSuggestionsResult?.quickLinks.forEach((element) => {
        element.icon = getIcon(element.app, 20);
      });

    searchSuggestionsResult?.links &&
      searchSuggestionsResult?.links.forEach((element) => {
        element.icon = getIcon(element.app, 20);
      });
  });

  return (
    <SearchBox
      searchString={searchString}
      onInput={setSearchString}
      onSearch={runSearch}
      quickLinks={searchSuggestionsResult?.quickLinks}
      historySearches={searchSuggestionsResult?.searchHistory}
      links={searchSuggestionsResult?.links}
    />
  );
};

export default SearchBar;
