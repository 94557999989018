import { isNumber } from '@monash/portal-frontend-common/dist/utils/number';
import { isStringEmpty } from '@monash/portal-frontend-common/dist/utils/string';

/**
 * getSearchResultLiveMsg
 * @param {boolean} isLoading
 * @param {false|object} error
 * @param {null|string} query
 * @param {undefined|number} resultCount
 * @returns {string} - live msg for screen reader
 */
export const getSearchResultLiveMsg = ({
  isLoading = false,
  error = false,
  query = '',
  resultCount = undefined,
} = {}) => {
  let msg = '';
  // Note: from current requirement, only need to announce when query search results are back
  if (!isLoading && !error && isNumber(resultCount)) {
    msg =
      resultCount === 0
        ? 'No result found'
        : `${resultCount} result${resultCount > 1 ? 's' : ''} found`;
    msg += isStringEmpty(query) ? '.' : ` for: "${query.trim()}".`;
  }
  // loading: reset live msg with content, forces screen reader to announce
  else if (isLoading) {
    msg = 'Searching.';
  }
  // otherwise reset live msg, some screen reader might ignore reset with empty string
  else {
    msg = '';
  }
  return msg;
};
