/**
 * sortLinksByVisited
 * @param {array} links - list of links data
 * @returns {array} sorted list of links data based on most visited scores
 */
const sortLinksByVisited = (links) =>
  [...links]?.sort(
    (a, b) =>
      (b.entityContent.tempScoreFromGAFixLaterPls || 0) -
      (a.entityContent.tempScoreFromGAFixLaterPls || 0)
  );

export default sortLinksByVisited;
