import React, { useContext, useState, useRef, useEffect } from 'react';
import c from './search.module.scss';
import { SearchContext } from '../../providers/search-provider/SearchProvider';
import PageContainer from '../../layouts/page-container/PageContainer';
import {
  QUERIES_SEARCH_RESULT_GRID,
  FilterDropdown,
  LoadingIndicator,
  useResponsiveValue,
  useMedia,
} from '@monash/portal-react';
import SearchResultsCard from '../../ui/search-result-card/SearchResultsCard';
import { RefContext } from '../../providers/accessibility-provider/AccessibilityProvider';
import filterLinksByCategory from '../../utilities/filter-and-sort/filterLinksByCategory';
import dummyLinks from '../../utilities/data/dummy-links.json';
import { Faq } from '@monash/portal-frontend-common';
import SearchBar from '../../utilities/search/SearchBar';
import SearchError from './SearchError';
import { categoryData } from '../category/data';
import { getSearchResultLiveMsg } from './search-utils';

const Search = () => {
  const {
    runSearchCallCount,
    loadingLinks,
    searchTarget,
    searchResult,
    searchError,
  } = useContext(SearchContext);
  const size = useResponsiveValue(QUERIES_SEARCH_RESULT_GRID, 1);
  const pageSize = useMedia();

  // Create keyword map from search results with unique keywords
  const suggestedKeywordsMap = new Map();
  const searchTerms = [
    ...new Set(searchTarget?.trim().toLowerCase().split(' ')),
  ];

  searchResult?.forEach((i) => {
    const uniqueKeywords = [...new Set(Object.values(i.keywords))];
    uniqueKeywords.forEach((keyword, i) => {
      // Count total number of each unique keyword from search result
      const count = suggestedKeywordsMap.get(keyword);
      if (searchTerms.includes(keyword)) {
        uniqueKeywords.splice(i, 1);
      } else if (!count) {
        suggestedKeywordsMap.set(keyword, 1);
      } else {
        suggestedKeywordsMap.set(keyword, count + 1);
      }
    });
  });

  // Get all refs
  const { setRefTable, setAppLivePoliteMsg } = useContext(RefContext);
  const searchResultRef = useRef();

  // Add search result ref to refTable
  useEffect(() => {
    setRefTable((f) => {
      return { ...f, search: searchResultRef };
    });
  }, [setRefTable]);

  // Filters and sorting
  const categories = categoryData.map((c) => c.name);
  const categoryFilters = ['All categories', ...categories];
  const [categoryFilter, setCategoryFilter] = useState([categoryFilters[0]]);

  // Display links
  const displayAllLinks = searchResult;
  const filteredLinks = categoryFilter.includes(categoryFilters[0])
    ? displayAllLinks
    : filterLinksByCategory(categoryFilter, displayAllLinks);
  const displayLinks = loadingLinks
    ? dummyLinks
    : categoryFilters.indexOf(categoryFilter) === 0
    ? displayAllLinks
    : filteredLinks;

  const categorySelection = (x) => {
    const before = categoryFilter.includes(categoryFilters[0]);
    const after = x.includes(categoryFilters[0]);
    const defaultOption = categoryFilters[0];

    // if empty, select 'all'
    if (x.length === 0) {
      setCategoryFilter([defaultOption]);
      return;
    }

    // if 'all' is selected, remove it
    if (before) {
      const temp = [...x];
      temp.splice(temp.indexOf(defaultOption), 1);
      setCategoryFilter(temp);
      return;
    }

    // if selecting 'all', ONLY select 'all'
    if (after) {
      setCategoryFilter([defaultOption]);
      return;
    }
    setCategoryFilter(x);
  };

  const resultTotal = (searchResult) => {
    let totalText = ' results';
    if (searchResult.length <= 1) {
      totalText = ' result';
    }
    return (
      (searchResult ? searchResult.length : displayLinks.length) + totalText
    );
  };

  // Note: have a short dependency list to make sure live msg is only updated when we need to
  // - loadingLinks: to help reset live region when same search executed continuously
  // - runSearchCallCount: to only update live region when each search API call is resolved
  useEffect(() => {
    setAppLivePoliteMsg(
      getSearchResultLiveMsg({
        isLoading: loadingLinks,
        query: searchTarget,
        error: searchError,
        resultCount: searchResult?.length,
      })
    );
  }, [loadingLinks, runSearchCallCount]);

  return (
    <PageContainer type="default">
      {pageSize === 'S' && <SearchBar />}

      {loadingLinks && (
        <div className={c.loading}>
          <LoadingIndicator />
        </div>
      )}
      {!loadingLinks && searchError && <SearchError />}
      {!loadingLinks && !searchError && (
        <div ref={searchResultRef} tabIndex="-1" className={c.searchResult}>
          <div className={c.header}>
            <div>
              <span tabIndex="-1">{resultTotal(searchResult)}</span>
            </div>
            <div className={c.filters}>
              <FilterDropdown
                filters={categoryFilters}
                selectedFilter={categoryFilter}
                setSelectedFilter={categorySelection}
                multi
              />
            </div>
          </div>
          <div className={c.links} id="searchResult">
            {displayLinks?.slice(0, size * 2).map((item, i) => (
              <SearchResultsCard
                entity={item.entityContent}
                key={i}
                index={i}
              />
            ))}
          </div>
          <div className={c.faq}>
            <Faq searchTerm={searchTarget} title="Related FAQs" />
          </div>
          {displayLinks.length > size * 2 && (
            <div className={c.links} id="searchResultContinue">
              {displayLinks?.slice(size * 2)?.map((item, i) => (
                <SearchResultsCard
                  entity={item.entityContent}
                  key={i}
                  index={size * 2 + i}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </PageContainer>
  );
};

export default Search;
