import { Icon, Link } from '@monash/portal-react';
import React from 'react';
import c from './category.module.scss';

const Category = ({ name, icon, to }) => {
  return (
    <Link to={to}>
      <div className={c.category}>
        <div className={c.icon}>{icon}</div>
        <h2>{name}</h2>
        <div className={c.icon}>
          <Icon.ChevronRight size={24} color="#204F88" />
        </div>
      </div>
    </Link>
  );
};

export default Category;
