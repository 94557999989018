import React, { useState, useEffect } from 'react';
import c from './local-error.module.scss';
import { ErrorIllustration } from '../../utilities/icons/Illustrations';

const LocalError = ({
  title = 'Sorry, we are currently having trouble retrieving data.',
  message = (
    <p>
      Please try again later or alternatively you can visit{' '}
      <a href="https://www.monash.edu" target="_blank" rel="noreferrer">
        monash.edu.
      </a>
    </p>
  ),
}) => {
  const [alert, setAlert] = useState(null);

  const resetAlert = () =>
    setTimeout(() => {
      setAlert('');
    }, 0);

  useEffect(() => {
    setAlert(title);
    resetAlert();
  }, [title]);

  return (
    <div className={c.container}>
      {alert && (
        // role status makes sense for alerts over output element
        // eslint-disable-next-line jsx-a11y/prefer-tag-over-role
        <div role="status" className="virtuallyHidden">
          {alert}
        </div>
      )}
      <div className={c.illustration}>
        <ErrorIllustration />
      </div>
      <h1>{title}</h1>
      {message}
    </div>
  );
};
export default LocalError;
